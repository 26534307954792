import React, { useState, useEffect } from "react";
import Input from "../common/form";
import request from "../common/api";
import toast, { Toaster } from "react-hot-toast";

const Register = () => {
  if (localStorage.getItem("token")) {
    window.location.href = "/dashboard";
  }
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const recom = params.get('r');
  useEffect(() => {
    formData.recom = recom;
  }, []);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    recom: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      formData.ref = localStorage.getItem("referrer");
      const response = await request("post", "/api/user/register", formData);
      // 根据返回的 status 字段判断注册是否成功
      if (response.status) {
        toast.success("注册成功，正在跳转");
        localStorage.removeItem("referrer");
        localStorage.setItem("regisrer", "true");
        setTimeout(() => {
          window.location.href = "/login?u=" + formData.username;
        }, 1500);
      } else {
        toast.error("注册失败：" + response.message);
      }
    } catch (error) {
      toast.error("注册失败：" + error.message);
    }
  };

  return (
    <div className="flex-grow flex items-center justify-center">
      <Toaster />
      <div className="w-full max-w-md">
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <h1 className="text-2xl text-center mb-4">注册</h1>
          <Input
            title="用户名"
            label="username"
            type="text"
            name="username"
            placeholder="Username"
            onChange={handleChange}
          />
          <Input
            title="密码"
            label="password"
            type="password"
            name="password"
            placeholder="Password"
            onChange={handleChange}
          />
          <Input
            title="推荐人"
            label="recom"
            type="number"
            name="recom"
            placeholder="没有可不填"
            onChange={handleChange}
            value={recom}
          />
          <div className="flex items-center justify-between mb-4">
            <button
              className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              注册
            </button>
            <a
              href="/login"
              className="text-sm text-gray-600 hover:text-gray-800"
            >
              已有账户？
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
