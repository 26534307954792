import React, { useState, useEffect } from "react";
import Plan from "../common/plan";
import api from "../common/api";
import { formatBytes } from "../common/utils";
import toast, { Toaster } from "react-hot-toast";
import Confirm from "../common/confirm";

function PlanPage() {
    const [order, setOrder] = useState(true);
    const [planInfo, setplanInfo] = useState({});

    const getplanInfo = async () => {
        try {
            const response = await api("get", "/api/user/plan", null);
            if (response.message === "401") {
                localStorage.removeItem('token');
                localStorage.removeItem('expire');
                localStorage.removeItem('username');
                window.location.href = '/login';
            } else {
                setplanInfo(response.data);
            }
        } catch (error) {
            toast.error("网络错误，请稍后再试");
            console.error(error);
        }
    };

    const handleConfirmSelectPlan = async (id) => {
        if (order) {
            setOrder(false);
            try {
                const response = await api("post", "/api/user/plan", { planId: id });
                if (response.status) {
                    toast.success("订购成功");
                    // 删除本地userInfo缓存
                    localStorage.removeItem("userInfo");
                    setTimeout(() => {
                        window.location.href = "/dashboard?do=sub";
                    }, 1500);
                } else {
                    toast.error(response.message);
                    setOrder(true);
                    if (response.message === "余额不足") {
                        setTimeout(() => {
                            window.location.href = "/dashboard?do=charge";
                        }, 1500);
                    }
                }
            } catch (error) {
                setOrder(true);
                toast.error(error);
            }
        } else {
            toast.error("点击过快");
        }
    };
    const exToken = async () => {
        try {
            const response = await api("post", "/api/user/extoken", null);
            if (response.message === "401") {
                localStorage.clear();
                window.location.href = '/login';
            } else {
                if (response.status) {
                    toast.success("修改成功");
                    // 删除本地userInfo缓存
                    localStorage.removeItem("userInfo");
                    setTimeout(() => {
                        window.location.href = "/dashboard";
                    }, 1500);
                } else {
                    toast.error(response.message);
                }
            }
        } catch (error) {
            toast.error("网络错误，请稍后再试");
            console.error(error);
        }
    }
    useEffect(() => {
        getplanInfo();
    }, []);

    return (
        <main className="py-12 px-4 sm:px-6 lg:px-8">
            <Toaster />
            <div className="max-w-7xl mx-auto">
                <section className="mb-12">
                    <h1 className="text-5xl font-bold mb-8 text-center">兼容操作页面</h1>
                    <p className="text-xl text-gray-700 mb-12 text-center">
                        此页面下操作均无二次确认提醒，将会直接执行操作。
                    </p>
                    <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-4">
                        订购套餐
                    </h2>
                    <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                        {planInfo.length > 0 && planInfo.map((plan) => (
                            <Plan
                                key={plan.id}
                                title={plan.name}
                                price={`${plan.price}￥`}
                                onSelect={() => handleConfirmSelectPlan(plan.id)}
                                features={[`${plan.days === 0 ? '无期限' : `${plan.days}天`}`, `${formatBytes(plan.traffic)}流量`, `不限速&不限设备数`]}
                            />
                        ))}
                    </div>
                </section>
                <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-4">
                    其它操作
                </h2>
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold mx-2 py-2 px-4 rounded" onClick={() => exToken()}>修改订阅地址</button>
            </div>
        </main>
    );
}

export default PlanPage;
