// NotFound.js

import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="min-h-80 flex items-center justify-center bg-gray-100">
      <div className="max-w-md">
        <h2 className="text-4xl font-bold text-gray-800 mb-4">404 NotFound</h2>
        <p className="text-lg text-gray-600 mb-8">网页未找到</p>
        <Link
          to="/" // 设置链接目标为主页路径
          className="block px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
        >
          Go Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
