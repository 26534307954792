import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./page/home";
import LoginPage from "./page/login";
import RegisterPage from "./page/register";
import PlanPage from "./page/plan";
import NotFount from "./page/404";
import DashboardPage from "./page/dash";
import { PrivateRoute } from "./common/PrivateRoute";
import Header from "./page/header";
import HowPage from "./page/help";
import ForgetPage from "./page/forget";
import OldPage from "./page/old";

function App() {
  useEffect(() => {
    const referrer = document.referrer;
    if (referrer && localStorage.getItem('referrer') === null) {
      localStorage.setItem('referrer', referrer);
    }
  }, []);
  return (
    <div className="bg-gray-100 flex flex-col min-h-screen">
      {/* Header */}
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/forget" element={<ForgetPage />} />
        <Route
          path="/old"
          element={
            <PrivateRoute>
              <OldPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/plan"
          element={
            <PrivateRoute>
              <PlanPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/help"
          element={
            <PrivateRoute>
              <HowPage />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFount />} />
      </Routes>
      <footer className="bg-white shadow mt-auto">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          {/* Footer Content */}
          <div className="flex justify-center items-center">
            <p className="text-gray-500">
              © 2024 冲上云霄. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
