import React from 'react';

const Modal = ({ isOpen, title, children, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto" style={{ position: 'fixed' }}>
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true" style={{ position: 'fixed', transition: 'opacity 0.3s ease' }}>
                    <div className="absolute inset-0 bg-gray-500 opacity-75" style={{ position: 'absolute', backgroundColor: '#6B7280', opacity: '0.75' }}></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className="inline-block align-middle bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6" style={{ display: 'inline-block', verticalAlign: 'middle', backgroundColor: '#FFFFFF', borderRadius: '0.5rem', boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)', transform: 'translateY(0)', transition: 'transform 0.3s ease' }}>
                    <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10" style={{ backgroundColor: '#FEE2E2' }}>
                            <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" style={{ height: '1.5rem', width: '1.5rem', color: '#DC2626' }}>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title" style={{ fontSize: '1.125rem', lineHeight: '1.75rem', fontWeight: '500', color: '#1F2937' }}>
                                {title}
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500" style={{ fontSize: '0.875rem', color: '#6B7280' }}>
                                    {children}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                            style={{ backgroundColor: '#DC2626', color: '#FFFFFF', padding: '0.5rem 1rem', fontSize: '1rem', fontWeight: '500', borderRadius: '0.375rem', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)', transition: 'background-color 0.2s ease', outline: 'none' }}
                            onClick={onConfirm}
                        >
                            确认
                        </button>
                        <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                            style={{ backgroundColor: '#FFFFFF', color: '#374151', borderColor: '#D1D5DB', padding: '0.5rem 1rem', fontSize: '1rem', fontWeight: '500', borderRadius: '0.375rem', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)', transition: 'background-color 0.2s ease', outline: 'none' }}
                            onClick={onClose}
                        >
                            取消
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
