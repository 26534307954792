import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [isLogin, setIsLogin] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('token') && localStorage.getItem('expire')) {
      if (Date.now() > Number(localStorage.getItem('expire'))) {
        localStorage.clear();
        window.location.href = '/login';
      } else {
        setIsLogin(true);
      }
    }
  }, []);
  return (
    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
        <Link to="/" className="text-2xl font-bold text-gray-900">
          冲上云霄
        </Link>
        <div>
          {/* 根据是否登录显示不同的导航 */}
          {isLogin ? (
            <>
              <Link
                to="/plan"
                className="text-indigo-600 hover:text-indigo-900 font-medium mr-4"
              >
                选购
              </Link>
              <Link
                to="/dashboard"
                className="text-indigo-600 hover:text-indigo-900 font-medium mr-4"
              >
                面板
              </Link>
              <Link
                to="/help"
                className="text-indigo-600 hover:text-indigo-900 font-medium mr-4"
              >
                教程
              </Link>
              <Link
                to="/"
                onClick={() => {
                  localStorage.clear();
                  window.location.reload(); // 重新加载页面
                }}
                className="bg-red-600 hover:bg-red-700 text-white font-medium py-2 px-6 rounded-md"
              >
                注销
              </Link>
            </>
          ) : (
            <>
              <Link
                to="/login"
                className="text-indigo-600 hover:text-indigo-900 font-medium mr-4"
              >
                登录
              </Link>
              <Link
                to="/register"
                className="bg-indigo-600 hover:bg-indigo-700 text-white font-medium py-2 px-6 rounded-md"
              >
                注册
              </Link>
            </>
          )}
        </div>
      </div>
    </header>
  );
}
export default Header;
