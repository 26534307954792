import React, { useState, useEffect } from "react";
import Input from "../common/form";
import api from "../common/api";
import toast, { Toaster } from "react-hot-toast";
const LoginPage = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const usernameFromUrl = params.get('u');
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    formData.username = usernameFromUrl;
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api("post", "/api/user/login", formData);
      if (response.status) {
        localStorage.setItem("token", response.data);
        localStorage.setItem("expire", Date.now() + 1000 * 60 * 60 * 2);
        toast.success("登录成功");
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1500);
      } else {
        toast.error("登录失败：" + response.message);
      }
    } catch (error) {
      toast.error("网络错误，请稍后再试");
      console.error(error);
    }
  };
  return (
    <div className="flex-grow flex items-center justify-center">
      <Toaster />
      <div className="w-full max-w-md">
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <h1 className="text-2xl text-center mb-4">登录</h1>
          <Input
            title="用户名"
            name="username"
            onChange={handleChange}
            label="username"
            type="text"
            placeholder="Username"
            value={usernameFromUrl}
          />
          <Input
            title="密码"
            label="password"
            type="password"
            name="password"
            onChange={handleChange}
            placeholder="Password"
          />
          <div className="flex items-center justify-between mb-4">
            <button
              className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              登录
            </button>
            <a
              href="/forget"
              className="text-sm text-gray-600 hover:text-gray-800"
            >
              忘记密码？
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
