import React, { useState, useEffect } from "react";
import Plan from "../common/plan";
import api from "../common/api";
import { formatBytes } from "../common/utils";
import toast, { Toaster } from "react-hot-toast";
import Confirm from "../common/confirm";

function PlanPage() {
  const [order, setOrder] = useState(true);
  const [planInfo, setplanInfo] = useState({});
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getplanInfo = async () => {
    try {
      const response = await api("get", "/api/user/plan", null);
      if (response.message === "401") {
        localStorage.removeItem('token');
        localStorage.removeItem('expire');
        localStorage.removeItem('username');
        window.location.href = '/login';
      } else {
        setplanInfo(response.data);
      }
    } catch (error) {
      toast.error("网络错误，请稍后再试");
      console.error(error);
    }
  };

  const handleSelectPlan = (planId) => {
    setSelectedPlan(planId);
    setIsModalOpen(true);
  };

  const handleConfirmSelectPlan = async () => {
    if (order) {
      setOrder(false);
      try {
        const response = await api("post", "/api/user/plan", { planId: selectedPlan });
        if (response.status) {
          toast.success("订购成功");
          // 删除本地userInfo缓存
          localStorage.removeItem("userInfo");
          setTimeout(() => {
            window.location.href = "/dashboard?do=sub";
          }, 1500);
        } else {
          toast.error(response.message);
          setOrder(true);
          if (response.message === "余额不足") {
            setTimeout(() => {
              window.location.href = "/dashboard?do=charge";
            }, 1500);
          }
        }
      } catch (error) {
        setOrder(true);
        toast.error(error);
      }
    } else {
      toast.error("点击过快");
    }
    setIsModalOpen(false);
  };
  useEffect(() => {
    getplanInfo();
  }, []);

  return (
    <main className="py-12 px-4 sm:px-6 lg:px-8">
      <Toaster />
      <div className="max-w-7xl mx-auto">
        <section className="mb-12">
          <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-4">
            选购套餐
          </h2>
          <p className="text-center text-gray-600 mb-4">
            选择适合您的套餐，点击订购即可购买，购买后会替换现有套餐。
          </p>
          <p className="text-center text-gray-600 mb-12">
            如此页面无法操作可前往 <a className="text-blue-600" href="/old">兼容操作页面</a> 进行操作
          </p>
          <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {planInfo.length > 0 && planInfo.map((plan) => (
              <Plan
                key={plan.id}
                title={plan.name}
                price={`${plan.price}￥`}
                onSelect={() => handleSelectPlan(plan.id)}
                features={[`${plan.days === 0 ? '无期限' : `${plan.days}天`}`, `${formatBytes(plan.traffic)}流量`, `不限速&不限设备数`]}
              />
            ))}
          </div>
        </section>
      </div>
      <Confirm
        isOpen={isModalOpen}
        title="确认订购"
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmSelectPlan}
      >
        您确认要订购这个套餐吗？这会替换您当前的套餐。
      </Confirm>
    </main>
  );
}

export default PlanPage;
