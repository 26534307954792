// utils.js

/**
 * Converts bytes to a human-readable string with appropriate units.
 *
 * @param {number} bytes - The number of bytes to convert.
 * @returns {string} - The converted value as a human-readable string.
 */
export function formatBytes(bytes) {
    if (bytes === 0) return '0 B';
    
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const size = parseFloat((bytes / Math.pow(k, i)).toFixed(2));
    
    return `${size} ${sizes[i]}`;
  }
  